import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import classes from './styles.module.scss';

export const LandingHeader = () => {
  const [activeAnchor, setActiveAnchor] = useState(0);
  useEffect(() => {
    function handleScroll(this: Window, event: Event) {
      if (window.scrollY > 532 && window.scrollY < 2150) {
        setActiveAnchor(1);
      } else if (window.scrollY > 2150 && window.scrollY < 3209) {
        setActiveAnchor(2);
      } else if (window.scrollY > 3209) {
        setActiveAnchor(3);
      } else if (window.scrollY < 532) {
        setActiveAnchor(0);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={classes.header}>
      <div className={`${classes.header__content} ${classes.content}`}>
        <div className={`${classes.navigation__content}`}>
          <div className={`${classes.header__logo} ${activeAnchor === 0 && classes.underline}`}>
            <a href="#scrapex">
              <h1>Scrapex</h1>
            </a>
          </div>
          <nav className={`${classes.navigation}`}>
            <ul>
              <li className={`${activeAnchor === 1 && classes.underline}`}>
                <a href="#core-functions">Core Features</a>
              </li>
              <li className={`${activeAnchor === 2 && classes.underline}`}>
                <a href="#about">About Us</a>
              </li>
              <li className={`${activeAnchor === 3 && classes.underline}`}>
                <a href="#contact">Contact Us</a>
              </li>
            </ul>
          </nav>
        </div>
        <Link to="/login">
          <Button
            variant="contained"
            color="info"
            sx={{
              color: '#fff',
              marginLeft: '30px',
              px: '18px',
            }}
          >
            LOG IN
          </Button>
        </Link>
      </div>
    </header>
  );
};
