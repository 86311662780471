import { createSlice } from '@reduxjs/toolkit';
import {
  createHedgeThunk,
  getActiveHedgesThunk,
  getAtWorkHedgesThunk,
  getHedgeByIdThunk,
  getHedgeHistoryThunk,
  getUnhedgedThunk,
  initHedgeThunk,
  placeOrderThunk,
  setFixedExchangeThunk,
  setOrderFixedExchangeThunk,
  setQuotationThunk,
  setRejectWithMessageThunk,
  setRejectOrderThunk,
} from './thunk';
import { IHedge, IUnhedgedShipment } from '@app/types';

interface IHedgeState {
  activeHedges: IHedge[];
  atWorkHedges: IHedge[];
  hedgeHistory: IHedge[];
  unhedged: IUnhedgedShipment[];
  currentHedge: IHedge | null;
  isLoading: boolean;
}

const initialState: IHedgeState = {
  activeHedges: [],
  atWorkHedges: [],
  hedgeHistory: [],
  unhedged: [],
  currentHedge: null,
  isLoading: false,
};

const hedging = createSlice({
  name: 'hedgingSlice',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    resetCurrentHedge(state) {
      state.currentHedge = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initHedgeThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
      }
    });

    builder.addCase(getHedgeByIdThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
      }
    });
    builder.addCase(getActiveHedgesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.activeHedges = payload.hedges;
      }
    });
    builder.addCase(getActiveHedgesThunk.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(getAtWorkHedgesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload?.hedges) {
        state.atWorkHedges = payload.hedges;
      }
    });
    builder.addCase(getAtWorkHedgesThunk.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(getHedgeHistoryThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.hedgeHistory = payload.hedges;
      }
    });
    builder.addCase(getHedgeHistoryThunk.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(getUnhedgedThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.unhedged = payload.hedges;
      }
    });
    builder.addCase(getUnhedgedThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setQuotationThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.activeHedges = state.activeHedges.map((hedge) =>
          hedge.id === payload.id ? payload : hedge,
        );
      }
    });
    builder.addCase(setRejectOrderThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.activeHedges = state.activeHedges.filter((hedge) => hedge.id !== payload.id);
      }
    });
    builder.addCase(setRejectWithMessageThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.atWorkHedges = state.atWorkHedges.filter((hedge) => hedge.id !== payload.id);
        state.activeHedges = state.activeHedges.filter((hedge) => hedge.id !== payload.id);
      }
    });
    builder.addCase(setFixedExchangeThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.activeHedges = state.activeHedges.filter((hedge) => hedge.id !== payload.id);
      }
    });
    builder.addCase(setOrderFixedExchangeThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.atWorkHedges = state.atWorkHedges.filter((hedge) => hedge.id !== payload.id);
      }
    });
    builder.addCase(placeOrderThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.activeHedges = state.activeHedges.filter((hedge) => hedge.id !== payload.id);
      }
    });
  },
});

export const { actions, reducer } = hedging;
export default hedging.reducer;
