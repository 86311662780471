import { useNavigate } from 'react-router-dom';
import classes from './styles.module.scss';
import { ReactNode } from 'react';
import { Box } from '@mui/material';

interface ISidebarNavigationItem {
  title: string;
  link: string;
  isActive: boolean;
  icon: ReactNode;
}

export const SidebarNavigationItem = ({ title, link, isActive, icon }: ISidebarNavigationItem) => {
  const navigate = useNavigate();

  return (
    <li className={isActive ? `${classes.item} ${classes.activeItem}` : `${classes.item}`}>
      <button onClick={() => navigate('/' + link)} type="button">
        <Box mr={4}>{icon}</Box>
        {title}
      </button>
    </li>
  );
};
