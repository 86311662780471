import { useState } from 'react';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import membersImg from '../app/assets/members.png';
import hedgingImg from '../app/assets/hedging.png';
import logisticsImg from '../app/assets/logistics.png';
import interWorkImg from '../app/assets/inter-work.png';

import classes from './styles.module.scss';

export const LandingExperience = () => {
  const [counter, setCounter] = useState(0);
  const [nextBtnHidden, setNextBtnHidden] = useState(false);
  const [prevBtnHidden, setPrevBtnHidden] = useState(true);

  const stepWidth = 345;
  const screenWidth = window.innerWidth;
  let counterLimit = 1;

  if (screenWidth < 676) {
    counterLimit = 3;
  } else if (screenWidth < 1024 && screenWidth > 684) {
    counterLimit = 2;
  }

  const slideRight = () => {
    setCounter(counter + 1);
    if (counter === counterLimit - 1) {
      setNextBtnHidden(true);
      setPrevBtnHidden(false);
    } else if (counter < counterLimit) {
      setPrevBtnHidden(false);
    }
  };

  const slideLeft = () => {
    setCounter(counter - 1);
    if (counter === 1) {
      setNextBtnHidden(false);
      setPrevBtnHidden(true);
    } else if (counter > 0) {
      setNextBtnHidden(false);
    } else if (counter < 0) {
      setCounter(0);
    }
  };

  return (
    <section className={`${classes.experience} ${classes.section}`}>
      <main className={`${classes.experience__content} ${classes.content}`}>
        <div className={`${classes.experience__header}`}>
          <h2>Our Experience</h2>
        </div>
        <div className={`${classes.experience__slider}`}>
          <div className={`${classes.slider__control}`}>
            <button
              type="button"
              onClick={slideLeft}
              className={`${prevBtnHidden ? classes.hidden : ''}`}
            >
              <KeyboardArrowLeft />
            </button>
            <button
              type="button"
              onClick={slideRight}
              className={`${nextBtnHidden ? classes.hidden : ''}`}
            >
              <KeyboardArrowRight />
            </button>
          </div>
          <div className={`${classes.slider__container}`}>
            <div
              className={`${classes.slider__wrapper}`}
              style={{ transform: `translateX(-${stepWidth * counter}px)` }}
            >
              <figure className={`${classes.slider__item}`}>
                <figcaption>
                  <h3>Members of the BIR board</h3>
                  <p>
                    We are a member of the Bureau of International Recycling board – the only
                    international recycling organization, with members from 70 countries.
                  </p>
                </figcaption>
                <img src={membersImg} alt="members" />
              </figure>
              <figure className={`${classes.slider__item}`}>
                <figcaption>
                  <h3>International experience</h3>
                  <p>
                    We succeeded in cooperation with international financial institutions, having a
                    deep expertise in discount letters of credit and with factor deals.
                  </p>
                </figcaption>
                <img src={interWorkImg} alt="interwork" />
              </figure>
              <figure className={`${classes.slider__item}`}>
                <figcaption>
                  <h3>Logistics</h3>
                  <p>
                    We provide logistics worldwide and cooperate with logistics companies and sea
                    lines directly.
                  </p>
                </figcaption>
                <img src={logisticsImg} alt="logistics" />
              </figure>
              <figure className={`${classes.slider__item}`}>
                <figcaption>
                  <h3>Hedging</h3>
                  <p>
                    We hedge base commodities and exchange rates for our customers on various stock
                    and brokerage sites.
                  </p>
                </figcaption>
                <img src={hedgingImg} alt="hedging" />
              </figure>
            </div>
          </div>
          {/* <div className={`${classes.slider__map}`}>
            <div className={`${classes.slider__dot} ${classes.dotOne}`} />
            <div className={`${classes.slider__dot} ${classes.dotTwo}`} />
            <div className={`${classes.slider__dot} ${classes.dotThree}`} />
            <div className={`${classes.slider__dot} ${classes.dotFour}`} />
          </div> */}
        </div>
      </main>
    </section>
  );
};
