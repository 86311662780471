import { ScrapexBreadcrumbs } from '@common/components/navigation/BreadCrumbs';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useGetCurrentContract } from '../../hooks/useGetCurrentContract';
import { formatDate } from '@app/utils';
import { ContractStatusIndicator, LoadingSkeleton } from '@common/components';
import { ContractStatus } from '@app/types';
import { generateContractRowData, contractColumns } from './data';
import { useGetFileNameAndLink } from '@app/hooks';

const contractItemGridStyle: SxProps = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 2.7fr 2fr 2fr 1.5fr 1.5fr 1.5fr',
};

export const ContractHeader = () => {
  const { contractId } = useParams();
  const { contract, isLoading } = useGetCurrentContract(contractId);
  const contractRowData = generateContractRowData(contract);

  const { link } = useGetFileNameAndLink(contract?.contract);

  const breadcrumbs = [
    { name: 'Contracts', linkTo: '/contracts' },
    { name: contract?.contract_number },
  ];

  return (
    <Box>
      <ScrapexBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        sx={{
          height: '100%',
          backgroundColor: 'var(--white-color)',
          borderRadius: '8px',
          mt: '20px',
        }}
      >
        {isLoading && <LoadingSkeleton />}
        {!isLoading && (
          <>
            <Box
              sx={{
                p: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <h3>
                    Contract No.
                    {contract?.contract_number}
                  </h3>
                  <ContractStatusIndicator
                    status={contract?.deal_status || ContractStatus.Canceled}
                  />
                </Box>
                <Typography sx={{ color: 'var(--other-500)', mt: '8px' }}>
                  <h6>Contract Date {contract ? formatDate(contract.contract_date) : ''}</h6>
                </Typography>
              </Box>
              {link ? (
                <Button variant="contained" color="warning" href={link} target="_blank">
                  PDF CONTRACT
                </Button>
              ) : (
                <Button variant="contained" color="warning" disabled>
                  PDF CONTRACT
                </Button>
              )}
            </Box>
            <Box
              sx={{
                ...contractItemGridStyle,
                backgroundColor: 'var(--other-50)',
              }}
            >
              {contractColumns.map((column) => (
                <Box
                  key={column}
                  sx={{ p: '10px 16px', fontSize: '12px', color: 'var(--other-500)' }}
                >
                  {column}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                ...contractItemGridStyle,
                alignItems: 'center',
              }}
            >
              {contractRowData.map((item, index) => (
                <Box sx={{ p: '8px 16px' }} key={index}>
                  {item.value}
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
