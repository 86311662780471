import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export const OperatingTimeInfo: React.FC = () => {
  return (
    <Box sx={{ background: 'var(--other-50)', borderRadius: '4px' }}>
      <Box p="12px" display="flex" gap={1}>
        <InfoOutlined />
        <Typography>
          Hedge operating time on the current market from 7:00 to 16:00 (GMT +1 London)
        </Typography>
      </Box>
    </Box>
  );
};
