import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import bgHeroImage from '../app/assets/hero-bg.png';
import classes from './styles.module.scss';

export const LandingHero = () => {
  return (
    <section
      className={`${classes.hero}`}
      style={{
        backgroundImage: `linear-gradient(90deg, #05142b 0%, rgba(12, 36, 82, 0) 100%),
    url(${bgHeroImage})`,
      }}
    >
      <main className={`${classes.hero__content} ${classes.content}`} id="scrapex">
        <article className={`${classes.hero__text} ${classes.text}`}>
          <h1>
            SCRAPEX - <br />
            is an <span>ecosystem.</span>
          </h1>
          <p>End-users can buy secondary metals directly from small processors and scrap yards.</p>
          <Link to="/login">
            <Button color="info" variant="contained" sx={{ color: '#fff', px: 7, mt: 4 }}>
              START TRADING
            </Button>
          </Link>
        </article>
      </main>
    </section>
  );
};
