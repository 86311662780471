import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../assets/img/login.png';

import { Paper, Container, Box } from '@mui/material';

import classes from '../signup/styles.module.scss';
import {
  selectIsLoginInitialized,
  selectIsPhoneInput,
  actions,
  selectIsLoginFetching,
} from '../reducer/login/slice';
import { LogInPasswordFormik } from './LogInPasswordFormik';
import { LoginForm } from './components/loginForm';

import LoginImage from '../../assets/img/login.png';

export const Login = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.reset());
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      {/* <Box className={classes.box_container}>
        <Box
          className={classes.box}
          sx={{
            '& > :not(style)': {
              m: 1,
              width: 562,
              height: 'auto',
              padding: '4.4vh 6.6vh',
            },
          }}
        >
          <Paper elevation={3} className={classes.paper}>
            <>
              <LogInHeader />
              <SwitchLoginStateView />
              <FooterSignup />
            </>
          </Paper>
        </Box>
      </Box> */}
      <Box
        sx={{
          backgroundImage: `url(${LoginImage})`,
          backgroundSize: 'cover',
          height: '100%',
          width: '588px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'calc(100% - 588px)',
        }}
      >
        <LoginForm />
      </Box>
    </Box>
  );
};
