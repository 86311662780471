import classes from './styles.module.scss';
import kycIcon from '../app/assets/kyc-i.png';
import logisticsIcon from '../app/assets/logistics-i.png';
import arbitrationIcon from '../app/assets/arbitration-i.png';
import hedgingIcon from '../app/assets/hedging-i.png';
import factoringIcon from '../app/assets/factoring-i.png';
import bigDataIcon from '../app/assets/big-data-i.png';

export const LandingCoreFunctions = () => {
  return (
    <section id="core-functions" className={`${classes.coreFunctions} ${classes.section}`}>
      <main className={`${classes.coreFunctions__content} ${classes.content}`}>
        <div className={`${classes.coreFunctions__header}`}>
          <h2>Core Features</h2>
          <h4>The Scrapex platform has 6 unique properties that help users to trade Easier.</h4>
        </div>
        <div className={`${classes.coreFunctions__grid}`}>
          <figure className={`${classes.card}`}>
            <img src={kycIcon} alt="icon" />
            <figcaption className="card__text">
              <h3>KYC Procedures</h3>
              <p>
                The Scrapex regularly monitors sellers and buyers for integrity and reliability, and
                evaluates them by providing ratings.
              </p>
            </figcaption>
          </figure>
          <figure className={`${classes.card}`}>
            <img src={logisticsIcon} alt="icon" />
            <figcaption className="card__text">
              <h3>Logistics</h3>
              <p>
                The Scrapex implements the possibility of the seller to sell the goods from
                warehouse, or from any other point and the buyer to purchase the goods with delivery
                to anywhere he needs.
              </p>
            </figcaption>
          </figure>
          <figure className={`${classes.card}`}>
            <img src={arbitrationIcon} alt="icon" />
            <figcaption className="card__text">
              <h3>Arbitration</h3>
              <p>
                The Scrapex examines disputes on the quality of products, delivery time and volume
                of delivered products.
              </p>
            </figcaption>
          </figure>
          <figure className={`${classes.card}`}>
            <img src={hedgingIcon} alt="icon" />
            <figcaption className="card__text">
              <h3>Hedging</h3>
              <p>
                The Scrapex implements the possibility to fix exchange rates and exchange value of
                traded commodities 23 hours per day for each party.
              </p>
            </figcaption>
          </figure>
          <figure className={`${classes.card}`}>
            <img src={factoringIcon} alt="icon" />
            <figcaption className="card__text">
              <h3>Factoring</h3>
              <p>
                The possibility for the seller to receive money for the goods on the fact of
                shipment or delivery to the port, and for the buyer - the possibility to place an
                order with a deferred payment
              </p>
            </figcaption>
          </figure>
          <figure className={`${classes.card}`}>
            <img src={bigDataIcon} alt="icon" />
            <figcaption className="card__text">
              <h3>Big Data</h3>
              <p>
                The Scrapex carries out analysis of the scrap metal recycling market, interacts with
                international institutions, publishes price indices and reports on the basis of
                transactions.
              </p>
            </figcaption>
          </figure>
        </div>
      </main>
    </section>
  );
};
