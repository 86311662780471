/* eslint-disable import/namespace */
import { Divider } from '@mui/material';
import { LandingAboutUs } from './LandingAboutUs';
import { LandingContact } from './LandingContact';
import { LandingCoreFunctions } from './LandingCoreFunctions';
import { LandingExperience } from './LandingExperience';
import { LandingFooter } from './LandingFooter';
import { LandingHeader } from './LandingHeader';
import { LandingHero } from './LandingHero';
import classes from './styles.module.scss';

export const Landing = () => {
  return (
    <div className={classes.landing}>
      <LandingHeader />
      <LandingHero />
      <LandingCoreFunctions />
      <Divider sx={{ m: '0 auto' }} className={classes.hr} />
      <LandingAboutUs />
      <Divider sx={{ m: '0 auto' }} className={classes.hr} />
      <LandingExperience />
      <LandingContact />
      <LandingFooter />
    </div>
  );
};
