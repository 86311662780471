import classes from './styles.module.scss';

export const LandingAboutUs = () => {
  return (
    <section id="about" className={`${classes.about} ${classes.section}`}>
      <main className={`${classes.about__content} ${classes.content}`}>
        <article className={`${classes.about__text}`}>
          <h2>About Us</h2>
          <h4>
            As a major international trader, scrap generator and processor of scrap materials,
            Scrapex DMCC is a full and respected member of the international scrap metal recycling
            market.
          </h4>
        </article>
      </main>
    </section>
  );
};
