import { IHedge } from '@app/types';
import { formatDate, formatTime, renderDateOrNoneInDrawer } from '@app/utils';
import {
  CollapsableDataListItem,
  CommentCard,
  DataListItem,
  HedgingStatusIndicator,
  ShipmentCard,
  TextWithSubtitle,
} from '@common/components';
import { List } from '@mui/material';

export const HedgeRequestDrawerInfo = ({ hedge }: { hedge: IHedge }) => {
  return (
    <>
      <List sx={{ mb: '20px' }}>
        <DataListItem title="Status">
          <HedgingStatusIndicator status={hedge.hedge_status} />
        </DataListItem>
        <DataListItem title="Company">{hedge.company_name}</DataListItem>
        <DataListItem title="Contract">{hedge.contract_number}</DataListItem>
        <DataListItem title="Request Date">
          <TextWithSubtitle
            title={formatDate(hedge.request_time)}
            subtitle={formatTime(hedge.request_time)}
          />
        </DataListItem>
        <DataListItem title="Product/Metal">
          {hedge.product}/{hedge.metal}
        </DataListItem>
        <DataListItem title="Offered Formula">{hedge.formula}</DataListItem>

        {/* {hedge.market.prices.ask}/{hedge.marketprices.bid} {hedge.market.prices.metal} */}
        <CollapsableDataListItem title="Hedging Shipments" input={hedge.hedge_shipments.length}>
          {hedge.hedge_shipments.map((shipment) => (
            <ShipmentCard shipment={shipment} />
          ))}
        </CollapsableDataListItem>
        <DataListItem title="Hedging Weight"> {hedge.weight.toFixed(3)}</DataListItem>
        <DataListItem title="Limit Fixation Date">
          {renderDateOrNoneInDrawer(hedge.limit_fixation_date)}
        </DataListItem>
        {hedge.message && (
          <CollapsableDataListItem title="Comment">
            <CommentCard comment={hedge.message} />
          </CollapsableDataListItem>
        )}
      </List>
    </>
  );
};
