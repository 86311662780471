import classes from './styles.module.scss';

export const LandingFooter = () => {
  return (
    <footer className={`${classes.footer}`}>
      <div className={`${classes.footer__content}`}>
        <div className={`${classes.footer__info}`}>
          <h3>SCRAPEX DMCC</h3>
          <p>
            2906, HDS Tower, Cluster F, JLT <br />
            Dubai, United Arab Emirates <br />
            Licence: DMCC-898393 <br />
            Registration number: DMCC197766
          </p>
          <h5>
            <a href="mailto:info@scrapex.ae">info@scrapex.ae</a>
          </h5>
        </div>
        <div className={`${classes.footer__sitemap}`}>
          <h5>Sitemap:</h5>
          <a href="#core-functions">Core Features</a>
          <a href="#about">About Us</a>
          <a href="#contact">Contact Us</a>
        </div>
      </div>
      <p style={{ textAlign: 'center', fontSize: '12px' }}>All rights reserved.</p>
    </footer>
  );
};
