import httpClient from '@app/client/httpClient';
import { RolesEnum } from '@app/routes/routes';
import {
  connectionEstablished,
  startConnecting,
  stopConnecting,
} from '@app/socket/reducer/slice/slice';
import { hostname } from '@app/utils/hostname';

import { Middleware } from 'redux';
import { showNotification } from 'src/account/notifications/push';
import { getHedgeByIdThunk, getQuotationThunk } from 'src/account/reducer/hedging/thunk';
import { marketDataUpdate } from 'src/account/reducer/market/slice';
import {
  getHedgeNotificationsThunk,
  getOtherNotificationsThunk,
} from 'src/account/reducer/notifications/thunk';
import { updateShipmentsThunk } from 'src/account/reducer/shipments/thunks';
import {
  getActiveHedgesThunk,
  getHedgeByIdThunk as getAdminHedgeByIdThunk,
  getAtWorkHedgesThunk,
} from 'src/hedgeAdmin/reducer/hedging/thunk';

export const socketMiddleware: Middleware = (store) => {
  let socket: WebSocket;
  let reconnectInterval: NodeJS.Timeout | null = null;
  let reconnectAttempts = 0;
  const maxReconnectAttempts = 5;

  const withNotification = (action: Function, dataKey: string | null) => (data: any) => {
    showUserNotification(data);

    //fetch updated notifications based on notification type
    const { value } = data;
    switch (value) {
      case 'Hedge':
        store.dispatch(getHedgeNotificationsThunk() as any);
        break;
      case 'Shipment':
      case 'Deal':
        store.dispatch(getOtherNotificationsThunk() as any);
        break;
      default:
        break;
    }

    const relevantData = dataKey ? data[dataKey] : null;
    return action(relevantData);
  };

  const showUserNotification = (data: any) => {
    const state = store.getState();
    const role = state.auth.auth.role;
    if (role === RolesEnum.TRADER) {
      showNotification(data);
    }
    return null;
  };

  const withFetchHedges = (action: Function) => (data: any) => {
    //tables updating

    store.dispatch(getActiveHedgesThunk() as any);
    store.dispatch(getAtWorkHedgesThunk() as any);

    return action(data.hedge_id);
  };

  const eventActionMap = {
    //hedges
    HedgeUserCreatedResponse: getActiveHedgesThunk,
    HedgeAcmUserAcceptedQuotationResponse: withFetchHedges(getAdminHedgeByIdThunk),

    HedgeAcmUserRejectedQuotationResponse: withFetchHedges(getAdminHedgeByIdThunk),
    HedgeAdminTimeoutResponse: withFetchHedges(getAdminHedgeByIdThunk),
    HedgeUserRejectedTpRequestResponse: withFetchHedges(getAdminHedgeByIdThunk),
    HedgeUserRejectedResponse: withFetchHedges(getAdminHedgeByIdThunk),
    HedgeUserRejectedTpOrderResponse: withFetchHedges(getAdminHedgeByIdThunk),

    HedgeAdminPlacedHedgeResponse: withNotification(getHedgeByIdThunk, 'hedge_id'),
    HedgeFixedExchangeResponse: withNotification(getHedgeByIdThunk, 'hedge_id'),
    HedgeAdminRejectedResponse: withNotification(getHedgeByIdThunk, 'hedge_id'),

    HedgeAdminPlacedTpHedgeResponse: withNotification(getHedgeByIdThunk, 'hedge_id'),
    HedgeQuotationResponse: withNotification(getQuotationThunk, null),
    HedgeAdminCreatedResponse: withNotification(getHedgeByIdThunk, 'hedge_id'),
    HedgeUserTimeoutResponse: (data: any) => showUserNotification(data),

    //shipments
    ShipmentUpdatedResponse: withNotification(updateShipmentsThunk, 'shipment_id'),

    //deals
    DealUpdatedResponse: (data: any) => {
      showUserNotification(data);
    },
    DealCreatedResponse: (data: any) => {
      showUserNotification(data);
    },

    //market
    MarketUpdateResponse: (data: any) => {
      return marketDataUpdate(data);
    },
  };

  const connectWebSocket = async () => {
    try {
      const state = store.getState();
      const role = state.auth.auth.role;
      const endpoint = role === RolesEnum.ACCOUNT_MANAGER ? 'admin' : 'user';
      const response = await httpClient.get<{ token: string }>(`api/ws/${endpoint}/token`);
      const token = response.data.token;

      socket = new WebSocket(`wss://${hostname}/api/ws/${endpoint}?auth_token=${token}`);

      socket.onopen = () => {
        store.dispatch(connectionEstablished());
        clearInterval(reconnectInterval!);
        reconnectAttempts = 0;
        setInterval(() => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({ type: 'ping' }));
          }
        }, 10000);
      };

      socket.onclose = (event) => {
        console.warn(`WebSocket closed: code ${event?.code}, reason: ${event?.reason}`);

        if (reconnectAttempts < maxReconnectAttempts) {
          reconnectInterval = setTimeout(connectWebSocket, 5000);
          reconnectAttempts++;
        }
      };

      socket.onerror = (e) => {
        console.error('Socket error: ', e);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        const action = eventActionMap[data.type as keyof typeof eventActionMap];
        if (action) {
          store.dispatch(action(data));
        }
      };
    } catch (error) {
      console.error('Error fetching token:', error);
      return;
    }
  };

  const closeWebSocket = () => {
    if (socket) {
      socket.close();
    }
    if (reconnectInterval) {
      clearTimeout(reconnectInterval);
    }
  };

  return (next) => async (action) => {
    if (startConnecting.match(action)) {
      connectWebSocket();
    } else if (stopConnecting.match(action)) {
      closeWebSocket();
    }
    next(action);
  };
};
