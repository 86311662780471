export const enum AuthRoutes {
  LOGIN = '/login',
  SIGNUP = '/signup',
  COMPANY_REGISTRAION = '/companyregistration',
}

export const enum AccountRoutes {
  ACCOUNT = '/account',
  ABOUTME = 'aboutme',
  DOCUMENTS = 'documents',
  COMPANY = '/edit-company',
  COMPANY_CONTRACTS = 'contracts',
  COMPANY_HEDGING = 'hedging',
}

export const enum ListingsRoutes {
  LISTINGS = '/listings',
  CREATE_LISTING = '/create-listing',
  EDIT_LISTING = '/edit-listing',
  ACTUAL_LISTING = '/actual-listing',
}

export const enum AccountManagerRoutes {
  ACCOUNT_MANAGER = 'account-manager',
  COMPANIES = 'companies',
  CONTRACTS_DASHBOARD = 'contracts',
  HEDGE_REQUESTS = 'hedge-requests',
  HEDGE_AT_WORK = 'hedge-at-work',
  UNHEDGED = 'hedge-unhedged',
  HEDGE_HISTORY = 'hedge-history',
}

export const enum BidsRoutes {
  BIDS = '/bids',
  EDIT_BID = '/counter-offer',
}
