import classes from './styles.module.scss';

export const LandingContact = () => {
  return (
    <section id="contact" className={`${classes.contact} ${classes.section}`}>
      <main className={`${classes.contact__content} ${classes.content}`}>
        <div className={`${classes.contact__header}`}>
          <h2>Contact Us</h2>
          <p>
            If you are interested in possible cooperation, please contact us. We will reply as soon
            as possible.
          </p>
        </div>
        <form className={`${classes.contact__form}`} action="mailto:info@scrapex.ae">
          <input type="text" name="name" id="input-name" required placeholder="Your Name*" />
          <input type="text" name="compony" id="input-compony" placeholder="Your Company" />
          <input type="email" name="email" id="input-email" required placeholder="Your Email*" />
          <textarea
            required
            name="message"
            id="input-message"
            cols={30}
            rows={10}
            placeholder="Your Message*"
          />
          <p>Reply takes maximum 1 business day*</p>
          <button type="submit">
            <i className="fa fa-envelope" />
            &nbsp;&nbsp;Leave a Message
          </button>
        </form>
      </main>
    </section>
  );
};
